.down-arrows {
    text-align: center;

    img {
        width: 2.5rem;
    }

    &.small img {
        width: 1.5rem;
    }
}


@media (max-width:640px) {
    // .down-arrows {
    //     padding: 0 !important;
    // }

    .down-arrows:not(.small) {
        padding: 0 !important;

        img {
            width: 1.5rem;
        }
    }
}