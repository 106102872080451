@use '../../../variables' as vars;




.expenses {
    margin-top: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    // background: lightcoral;

    .container {
        display: flex;
        gap: 2rem;

        // grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));


        .tile {
            background-color: white;
            border-radius: 20px;
            padding: 0rem 1.5rem 1rem 1.5rem;
            box-shadow: 0px 10px 55px -6px rgba(92, 99, 109, 0.1);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            flex: 1 1 0px;
            overflow: visible;

            .main {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .icon {
                width: 40%;
                aspect-ratio: 1;
                transform: translateY(-42%);
            }

            .title {
                max-width: 100%;
                margin-top: -2.2rem;
                margin-bottom: .4rem;
                position: relative;
                text-align: center;
                line-height: 1.4;
                // white-space: nowrap;

                &::after {
                    content: '';
                    width: 70%;
                    height: 4px;
                    border-radius: 5px;
                    background-color: vars.$primary-color;
                    position: absolute;
                    bottom: -.6rem;
                    left: 50%;
                    transform: translateX(-50%);

                }
            }

            .description {
                width: 100%;
                margin-bottom: 0.5rem;
            }

        }
    }

    @media screen and (max-width: 800px) {

        .container {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;
            width: 100%;
            max-width: min(100%, 1080px);
            // margin: auto;
            padding-inline: 2rem;
            box-sizing: border-box;

            .tile {
                width: 80%;
                max-width: 500px;
                margin-bottom: 2rem;

                .icon {
                    width: 22%;
                }
            }
        }
    }

}