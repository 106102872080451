@use '../../../variables' as vars;


.projects {
    background-color: vars.$dark1-color;
    padding-top: 2rem;
    padding-bottom: 7rem;
    z-index: 2;

    * {
        color: vars.$bg-shade-color;
    }

    .container {
        z-index: 2;

        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            // margin-bottom: 2.5rem;
            color: vars.$bg-color;
            position: relative;

            .arrow1 {
                position: absolute;
                top: 0;
                right: max(-15%, -4vw);
                transform: translateX(100%) translateY(5%);

            }
        }

        .subtitle {
            max-width: 38rem;
            text-align: center;
            margin-bottom: 6rem;
            margin-top: 0;
        }

        .project {
            z-index: 1;

            h3 {
                position: relative;
                margin-top: 0;
                margin-bottom: .5rem;
                margin-left: 1rem;


                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -.65rem;
                    width: 4px;
                    height: 50%;
                    border-radius: 30px;
                    background-color: vars.$primary-color;

                }
            }

            .tile {
                border-radius: 20px;
                background-color: vars.$dark2-color;
                padding: 1rem;
                padding-right: 2rem;
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                gap: 1.5rem;
                margin-bottom: 6rem;

                .img-wrapper {
                    border-radius: 25px;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .divider {
                    width: 4px;
                    height: 80%;
                    border-radius: 30px;
                    background-color: vars.$primary-color;
                }

                .main {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    h4 {
                        margin: 0;
                        color: vars.$primary-darker-color;
                        font-size: .8rem;
                        font-weight: 500;

                    }

                    p {
                        margin-top: .5rem;
                        margin-bottom: 1.2rem;
                        // font-weight: 100;
                    }

                    .bottom-bar {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-self: flex-end;

                        p {
                            margin: 0 2rem;
                        }

                        .btn {
                            display: flex;
                            align-items: center;
                            text-decoration: none;
                            font-weight: 500;

                            border-radius: 14px;
                            background-color: vars.$dark3-color;
                            padding: .8rem 2rem .8rem 1rem;
                            transition: all .2s ease-in-out;



                            img {
                                margin-right: .8rem;
                                transform: translateY(-5%);
                                width: 1.3rem;
                            }

                            span {
                                transform: translateY(5%);

                            }

                            &:hover {
                                background-color: darken(vars.$dark3-color, 10%);
                            }
                        }
                    }
                }
            }

            // .tile.reversed {
            //     padding: 1rem;
            //     padding-left: 2rem;

            //     .img-wrapper {
            //         order: 2;
            //     }

            //     .divider {
            //         order: 1;
            //     }

            //     .main {
            //         order: 0;

            //         .bottom-bar {
            //             align-self: flex-start;
            //             flex-direction: row-reverse;
            //         }
            //     }
            // }


        }

        .blob-wrapper {
            width: 100%;
            position: relative;

            .b10 {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(85%) translateY(-70%) scale(.8);
            }
        }


        .secret-title {
            position: relative;

            .arrow2 {
                position: absolute;
                top: 0;
                left: max(-20%, -7vw);
                transform: translateX(-100%) translateY(-75%);
            }
        }
    }


    @media screen and (max-width: 880px) {
        padding-top: 1rem;

        .container {
            .arrow1 {
                display: none;
            }

            .project {
                .tile {
                    grid-template-columns: 1fr auto 1.4fr;
                    padding: 1rem;

                    .main .bottom-bar p {
                        margin: 0;
                        margin-right: 1rem;
                    }
                }

                .tile.reversed {
                    grid-template-columns: 1.4fr auto 1fr;
                    padding: 1rem;

                    .main .bottom-bar p {
                        margin: 0;
                        margin-left: 1rem;
                    }
                }
            }
        }


    }

    @media screen and (max-width: 700px) {
        .container .project {

            .tile,
            .tile.reversed {
                grid-template-columns: 1fr;
                gap: 1.5rem;
                margin-bottom: 3rem;

                .main .bottom-bar p {
                    margin-right: 1rem;
                }
            }

        }

    }
}