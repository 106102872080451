.spacing-wave {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
        width: 100%;
        min-width: 900px;
        // margin-bottom: -4px;
    }
}