@use '../../../variables' as vars;




.story {
    width: 100%;
    padding-bottom: 3rem;

    .container {
        text-align: center;
        position: relative;

        .b4 {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(80%) translateY(-40%) scale(.8);
        }

        h2 {
            margin-top: 0;
        }


        p {
            max-width: 39rem;
            margin: auto;
            // font-weight: 400;
            // font-size: 1.1rem;
        }

        a {
            margin: 2rem;
        }
    }
}