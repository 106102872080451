@use '../../../variables' as vars;

.wave-technologies {
    path {
        fill: vars.$bg-shade-color;
    }
}

.technologies {
    width: 100%;
    background-color: vars.$bg-shade-color;
    padding-top: 8rem;

    .container {
        text-align: center;
        position: relative;


        .b5 {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-100%) translateY(-70%);
        }

        h1 {
            margin-top: 0;
        }


        .table {
            display: grid;
            grid-template-columns: 2fr 3fr;
            gap: 3rem;
            text-align: left;

            .sections {
                .tile {
                    border-radius: 20px;
                    padding: .8rem 1rem;
                    margin-bottom: 1.3rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .icon {
                        width: 4.3rem;
                        margin-right: 1rem;
                        border-radius: 50%;
                        box-shadow: 0px 10px 55px -6px rgba(92, 99, 109, 0.1);
                    }

                    .title {
                        margin: 0;
                        transform: translateY(5%);
                    }

                    .project-count {
                        margin: 0;
                        margin-left: .2rem;
                        font-size: .9rem;
                        color: vars.$dark4-color;
                    }


                    &,
                    * {

                        transition: all .2s ease-in-out;
                        // transition-delay: .5s;
                    }


                    // -------------------

                    &.selected {
                        background-color: white;
                        filter: drop-shadow(0px 5px 18px rgba(186, 192, 210, 0.25));

                    }

                    &:not(.selected) {
                        .title {
                            color: vars.$dark3-color;
                        }
                    }

                    &:hover:not(.selected) {
                        background-color: rgba(255, 255, 255, .5);
                        // outline: 3px solid vars.$dark4-color;
                    }

                    &:focus {
                        outline: 3px solid vars.$dark4-color;
                    }
                }
            }

            .descriptions {

                h3 {
                    position: relative;
                    margin-bottom: 0rem;
                    margin-top: 0;


                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -.65rem;
                        width: 4px;
                        height: 50%;
                        border-radius: 30px;
                        background-color: vars.$primary-color;

                    }
                }

                p {
                    margin-top: .5rem;
                    margin-bottom: 1.5rem;
                }
            }
        }


        .design {
            width: 100%;
            height: 17rem;
            position: relative;

            .blobs {
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(75%) translateY(-30%);


                .b6 {}

                .b7 {
                    position: absolute;
                    top: 0;
                    left: 0;
                    transform: translateX(-95%) translateY(35%);
                }
            }
        }
    }
}