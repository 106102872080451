@use '../variables' as vars;

// * {
//     outline: 1px solid lime;
// }

footer {
    width: 100%;
    background-color: black;
    padding-top: 8rem;
    // transform: translateY(-10rem);

    // overflow-y: hidden;

    .container {
        display: flex;
        align-items: flex-end;
        position: relative;


        .blobs-left {
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateX(-75%) translateY(0%);

            .b16 {}

            .b17 {
                position: absolute;
                bottom: 15%;
                right: -40%;
            }
        }


        .links {
            display: flex;
            gap: 3rem;
            padding: 0;
            margin: 0 auto;
            margin-bottom: 6rem;
            z-index: 1;

            li {
                list-style: none;

                a {
                    color: vars.$bg-color;
                    text-decoration: none;
                    transition: color 0.2s ease-in-out;
                    font-weight: 500;

                    &:hover {
                        color: vars.$primary-color;
                    }
                }
            }
        }

        .blob-right {
            position: absolute;
            bottom: 0;
            right: 0;
            overflow: hidden;
            height: 20rem;
            transform: translateX(50%);
        }
    }

    .bottom-bar {
        width: 100%;
        border-top: 1px solid vars.$dark2-color;

        p {
            font-weight: 500;
            font-size: .8rem;
            color: vars.$bg-color;
            text-align: center;
            margin: 0;
            padding: .5rem;
        }
    }

}

@media screen and (max-width: 800px) {
    footer {
        padding-top: 5rem;

        .container {
            .blobs-left {
                transform: translateX(-60%) translateY(10%) scale(.8);

                .b17 {
                    display: none;
                }
            }

            .blob-right .b18 {
                transform: translateX(10%) rotate(-10deg) scale(.7);
            }
        }
    }
}