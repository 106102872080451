@use 'variables' as vars;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');


// * {
//   outline: 1px solid lime;
// }

html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: vars.$bg-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //remove blue overlay on mobile
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  line-height: 1.8;
  color: vars.$dark1-color;
}

button {
  font-size: 1rem;
  font-weight: bold;
}

h1 {
  // font-size: 3.3rem;
  font-size: min(3.3rem, 6vw);
  font-weight: 600;
  margin-bottom: 2.5rem;
}

a {
  color: vars.$primary-accent-color;
}

h2 {
  font-size: 2.2rem;
  font-weight: 500;
}

h3 {
  font-size: 1.6rem;
  font-weight: 600;
  // line-height: 1.7;
}

p {
  color: vars.$dark3-color;
  font-size: 1rem
}


#root {
  max-width: 100vw;
}



.container {
  width: 100%;
  max-width: min(100%, 1080px);
  // margin: auto;
  padding-inline: 2rem;
  box-sizing: border-box;
}



.center-child {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outer-container {
  width: 100%;
  max-width: min(100%, 1380px);
  // margin: auto;
}

main {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  max-width: 1300px;
  background: purple;
  // flex-shrink: 0;

  // display: grid;
  // grid-template-columns: 1fr min(100%, 960px) 1fr;
}


// .flex {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// }



a.styled {
  display: inline-block;
  color: vars.$primary-color;
  font-weight: 500;
  text-decoration: none;
  display: inline-block;

  &::after {
    content: "";
    width: 0px;
    height: 2px;
    border-radius: 10px;
    display: block;
    background: vars.$primary-color;
    transition: 300ms;
    margin-top: -0.2em;
  }

  &:hover::after {
    width: 100%;
  }
}


@media (max-width: 800px) {
  :root {
    font-size: clamp(11px, 3vw, 16px);
  }
}