@use '../../../variables' as vars;

// * {
//     outline: 1px solid lime;
// }


.contact {
    width: 100%;
    background-color: vars.$dark1-color;
    padding-top: 3rem;

    * {
        color: vars.$bg-color;
    }

    .title {
        position: relative;
        margin-left: .5rem;
        margin-bottom: 1rem;
        align-items: flex-start;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -.8rem;
            width: 4px;
            height: 60%;
            border-radius: 30px;
            background-color: vars.$primary-color;
        }
    }

    .container .grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: min(4rem, 4vw);
        padding-bottom: 4rem;


        .text {
            display: flex;
            flex-direction: column;

            .subtitle {
                color: vars.$bg-shade-color;
                margin-left: 2rem;
                max-width: 26rem;

            }


            .wrap {
                position: relative;
            }

            .paper-plane {
                position: absolute;
                padding-right: 4rem;

                right: -2.5rem;
                top: 2rem;

                transform: scale(.8);

                // transform: translateX(-5rem) translateY(5rem);

                .trace {}

                .plane {
                    width: 4rem;
                    position: absolute;
                    right: 2%;
                    top: 5%;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;

            .prompt {
                margin-bottom: 1rem;
                width: 100%;


                p {
                    margin-left: .5rem;
                    margin-bottom: .1rem;
                    position: relative;
                    font-weight: 500;


                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: -.65rem;
                        width: 4px;
                        height: 50%;
                        border-radius: 30px;
                        background-color: vars.$primary-color;
                    }
                }

                input,
                textarea {
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    background-color: vars.$dark2-color;
                    border: none;
                    border-radius: 5px;
                    padding: .5rem;
                    width: 100%;

                    &:focus {
                        outline: none;
                    }

                }

                #message {

                    width: 100%;
                    min-height: 10rem;
                    resize: none;

                }

                /* width */
                ::-webkit-scrollbar {
                    width: 8px;
                    cursor: pointer;
                }

                /* Track */
                ::-webkit-scrollbar-track {
                    background: vars.$dark2-color;
                    border-radius: 10px;
                }

                /* Handle */
                ::-webkit-scrollbar-thumb {
                    background: vars.$dark3-color;
                    border-radius: 10px;
                    cursor: pointer;
                }

                /* Handle on hover */
                ::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
            }

            button {
                align-self: center;
                border: none;
                border-radius: 18px;
                color: vars.$bg-color;
                background-color: darken(vars.$primary-color, 5%);
                font-weight: bold;

                padding: 0.75rem 1.8rem;
                margin-top: 2rem;

                &:hover {
                    background-color: darken(vars.$primary-color, 15%);
                }
            }
        }
    }


    @media (max-width: 850px) {
        .container {
            display: flex;
            flex-direction: column;
            align-items: center;

            .grid {
                grid-template-columns: 1fr;

                .text {
                    margin-bottom: 8.5rem;

                    .title {
                        align-self: center;
                    }


                    .paper-plane {
                        top: 0;
                        right: -20%;
                        // left: 0;
                    }
                }

                form {
                    align-items: center;

                    .prompt {
                        max-width: 30rem;
                    }
                }
            }
        }
    }
}