@use '../../../variables' as vars;

.experience {
    width: 100%;
    background-color: vars.$bg-shade-color;
    padding-bottom: 8rem;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            padding-bottom: 1rem;
        }


        .table {

            .tile {
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                gap: 2rem;

                margin-bottom: calc(1.8 * 1.5rem / 4 - .7rem);

                h3 {
                    margin-top: 0;
                    margin-bottom: 1rem;
                }

                p {
                    margin-bottom: 2rem;
                }

                .left {
                    h3 {}

                    p {
                        margin-left: .5rem;
                    }
                }

                .right {
                    h3 {}

                    p {}
                }

                .divider {
                    --stroke-width: 2px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .circle {
                        width: 1.3rem;
                        aspect-ratio: 1;
                        border-radius: 50%;
                        background-color: vars.$primary-color;
                        position: relative;
                        margin-top: .7rem;

                        &::after {
                            content: '';
                            width: 180%;
                            aspect-ratio: 1;
                            border-radius: 50%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border: var(--stroke-width) dashed vars.$dark3-color
                        }
                    }

                    .line {
                        flex-grow: 1;
                        margin-top: calc(1.8 * 1.5rem / 4);
                        border-left: var(--stroke-width) dashed vars.$dark3-color
                    }

                    .line {}
                }

                // make the divider line the same height as the tile
                &:last-child {
                    .divider .line {
                        margin-bottom: .5rem;
                    }

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }


    }
}