@use '../../variables' as vars;

.up-arrow {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 2.5rem;
    aspect-ratio: 1;
    border-radius: 8px;
    background-color: vars.$bg-shade-color;
    outline: 3px solid vars.$dark4-color;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    z-index: 100;
    transition: all .2s ease;

    svg {
        color: vars.$dark3-color;
        transform: translateY(-1px) scale(.8);
        width: 66%;
        transition: all .2s ease;
    }

    &:hover svg {
        color: vars.$dark2-color;
    }

}

.hidden {
    opacity: 0;
}