@use '../../../variables' as vars;


.wave-certifications {
    // outline: 1px solid lime;
    // transform: translateY(-100%);


    path {
        fill: vars.$bg-color;
        // fill: orange;
    }

}

.certifications {
    width: 100%;
    padding-top: 4rem;



    // margin-bottom: calc(9rem - 8vw);

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .title {
            // margin-bottom: 3rem;
            z-index: 1;
        }

        .subtitle {
            margin-top: 0;
            z-index: 1;
        }

        .blobs-top {
            position: relative;
            width: 100%;
            transform: translateY(-9rem);
            // z-index: -1;

            .left {
                position: absolute;
                left: 0;
                top: 0;
                transform: translateX(-80%) translateY(-10%) scale(.8);
                z-index: -1;

                .b11 {}

                .b12 {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    transform: translateX(100%) translateY(15%);
                }
            }

            .b13 {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateX(80%) translateY(-12%);
                z-index: -1;

            }


        }

        .subtitle {
            text-align: center;
            max-width: 40rem;
            margin-bottom: 4rem;
        }

        .certificates {
            width: 100%;

            .tile {
                border-radius: 20px;
                background-color: white;
                box-shadow: 0px 10px 55px -6px rgba(92, 99, 109, 0.1);
                padding: 1.4rem 2rem;
                position: relative;
                margin-bottom: 2rem;
                width: 100%;
                box-sizing: border-box;
                cursor: pointer;


                &.expanded {
                    .expanded-section {
                        // max-height: 30rem;
                        // transform: scaleY(1);
                        // clip: rect(auto, auto, auto, auto);

                    }
                }

                .shrinked-section {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .text-wrapper {
                        // flex-grow: 1;
                        width: 65%;

                        h3 {
                            margin-top: 0;
                            margin-bottom: .5rem;
                        }


                        p {
                            margin-top: 0;
                            margin-bottom: .5rem;
                        }
                    }

                    // .date-download {
                    //     display: flex;
                    //     align-items: center;
                    //     gap: 3rem;
                    // }

                    .date.dimmed {
                        color: vars.$dark4-color;
                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        font-weight: 500;

                        border-radius: 14px;
                        background-color: vars.$bg-shade-color;
                        padding: .7rem 2rem .7rem 1rem;
                        transition: all .2s ease-in-out;

                        .icon {
                            display: grid;
                            place-items: center;
                        }


                        .icon * {
                            margin-right: .8rem;
                            transform: translateY(-5%);
                            width: 1.3rem;

                            * {
                                fill: vars.$dark3-color;
                            }
                        }

                        span {
                            transform: translateY(5%);
                            color: vars.$dark3-color;
                        }

                        @media screen and (max-width: 880px) {
                            & {
                                padding: .7rem;

                                .icon * {
                                    margin: 0;
                                }

                                span {
                                    display: none;
                                }
                            }
                        }

                        &:hover {
                            background-color: darken(vars.$bg-shade-color, 10%);
                        }

                    }

                }
            }

            .expanded-section {
                display: flex;
                flex-direction: column;
                // max-height: 0;
                // transform: scaleY(0);
                // transform-origin: top;
                // clip: rect(auto, auto, 0, auto);
                // clip-path: inset(10px 150px);
                // height: 0;
                overflow: hidden;
                transition: all .5s ease-in-out;

                ul {
                    max-width: 40rem;

                }

                a {
                    align-self: flex-end;
                    margin: .5rem 1rem
                }

            }

            .arrow {
                --width: 50px;
                width: var(--width);
                position: absolute;
                bottom: -.3rem;
                left: calc(50% - var(--width) / 2);
                padding: 1rem 0;
                display: flex;
                justify-content: center;
            }

            .flipped {
                transform: scaleY(-1);
            }
        }
    }


    .blobs {
        position: relative;
        transform: translateX(-80%) translateY(20%);
        z-index: -1;

        .b14 {}

        .b15 {
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%) translateY(-20%);
        }
    }
}


@media (max-width:800px) {
    .certifications .container {
        .blobs-top {
            transform: translateY(-5.5rem);
            // z-index: -1;
        }

        .tile .expanded-section ul {
            padding-left: 1.2rem;
            // padding-left: 0;
        }

    }
}

@media (max-width:640px) {
    .certifications {
        padding-top: 2rem;
        padding-bottom: 7rem;

        // .blobs-top {
        //     // transform: translateY(-3rem);
        // }

        .blobs {
            display: none;
        }
    }
}