@use '../../../variables' as vars;



.landing {
    width: 100%;

    .outer {
        display: flex;
        justify-content: center;
        position: relative;



        .b3 {
            position: absolute;
            width: 17rem;
            top: 78%;
            left: -12rem;
            z-index: -1;
        }


        .social-sites {
            left: 0;
            display: grid;
            gap: 1rem;
            flex-shrink: 0;
            margin-inline: 1rem;
            z-index: 2;
            height: min-content;
            position: absolute;
            top: 44%;
            transform: translateY(-50%);
            left: 0;


            .tile {
                width: 2.5rem;
                aspect-ratio: 1;
                border-radius: 50%;
                background-color: vars.$bg-shade-color;
                display: grid;
                place-items: center;
                color: vars.$primary-color;
                box-shadow: 0px 0px 4.13994px rgba(64, 84, 135, 0.1);
                border-radius: 47.6093px;
                position: relative;

                &:hover {
                    box-shadow: none;
                }

                .icon {
                    width: 45%;
                    aspect-ratio: 1;
                    fill: orange;
                    opacity: 1;
                    transition: all 0.7s ease;
                    transition-delay: 0.7s;
                }

                &:hover .icon {
                    opacity: 0;
                    transition-delay: 0s;
                }


                .link {
                    text-decoration: none;
                    width: auto;
                    max-width: 100%;
                    height: 100%;
                    padding: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    visibility: hidden;
                    white-space: nowrap;
                    overflow: hidden;
                    background-color: vars.$bg-shade-color;
                    border-radius: 1000px;
                    display: grid;
                    place-items: center;
                    transition:
                        max-width 0.5s ease-in-out,
                        padding-inline 0.5s ease-in-out,
                        visibility 0.5s ease-in-out;

                    p {
                        margin: 0;
                        color: vars.$primary-color;
                        text-decoration: none;
                        font-weight: 500;
                        // &:hover {
                        //     font-weight: 800;
                        // }
                    }
                }

                &:hover .link {
                    visibility: visible;
                    max-width: 20rem;
                    padding-inline: 1.2rem;
                    box-shadow: 0px 0px 4.13994px rgba(64, 84, 135, 0.1);
                }


            }

        }

        .container {
            display: flex;
            align-items: center;

            .main-content {
                display: flex;
                flex-direction: column;
                margin-left: 2rem;
                margin-top: 10%;
                flex-shrink: 0;
                width: fit-content;

                .title-wrapper {
                    position: relative;

                    .vertical-line {
                        position: absolute;
                        top: 5%;
                        left: -2rem;
                        width: 5px;
                        height: 80%;
                        background: vars.$primary-color;
                        border-radius: 5px;
                    }

                    .welcome {
                        margin: 0;
                        font-weight: 500;
                        font-size: 1.8rem;
                        color: vars.$dark3-color;
                    }

                    .name {
                        margin: 0;
                    }
                }

                .subtitle {
                    max-width: 29rem;
                    color: vars.$dark4-color;
                }

                .contact-btn {
                    // display: inline-block;
                    background-color: vars.$primary-color;
                    border: transparent;
                    border-radius: 18px;
                    box-shadow: 0px 0px 5px rgba(239, 194, 80, 0.7);
                    margin-top: 1rem;
                    padding: .75rem 1.8rem;
                    color: vars.$bg-color;
                    text-decoration: none;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: bold;
                    width: fit-content;

                    transition: all 0.3s ease;

                    &:hover {
                        background-color: darken(vars.$primary-color, 5%);
                    }
                }

            }

            .photo-section {
                // margin-left: -2rem;
                z-index: -1;
                position: relative;
                max-width: clamp(40rem, 50vw, 50rem);
                flex-shrink: 0;

                .b1 {
                    width: 100%;
                }

                .portrait {
                    // potom predelam na jeden img
                    // visibility: hidden;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 65%;
                    position: absolute;
                }

                .b2 {
                    width: 32%;
                    position: absolute;
                    left: -25%;
                    bottom: -19%;
                }
            }

        }
    }

    @media (max-width:1200px) {
        .outer .social-sites {
            top: 2rem;
            transform: none;
        }
    }

    @media (max-width:880px) {
        .container {
            flex-direction: column-reverse;

            .main-content {
                .contact-btn {
                    // align-self: flex-end;
                }
            }

            .photo-section {
                margin-top: .5rem;
                margin-bottom: -5rem;
                max-width: 50vw !important;
                transform: translateX(22%);
            }
        }

        .outer .social-sites {
            top: 2rem;
            left: 1rem;
        }

        .outer .b3 {
            transform: scale(.8);
        }

    }
}