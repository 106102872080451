@use '../../../variables' as vars;

@import url('http://fonts.cdnfonts.com/css/calibre');




.quote {
    width: 100%;
    background: black;
    padding-top: 3rem;

    .main {
        display: flex;
        justify-content: space-between;
        align-items: center;

        * {
            font-family: "Calibre", sans-serif;
        }

        h1 {
            color: #ACACAC;
            margin-bottom: 1rem;
            line-height: 1.3;
        }

        p {
            color: #ACACAC;
            text-align: right;
            margin-right: 1rem;
        }


        .mark-wrapper {
            position: relative;
            z-index: 1;

            .mark {
                width: clamp(8rem, 13vw, 12rem);
                transform: translateX(5rem) translateY(-3rem);

                path {
                    fill: vars.$primary-color
                }

                &:nth-child(1) {
                    position: absolute;
                    z-index: -1;
                    // transform: translateX(1.5rem) translateY(1rem);
                    top: 8%;
                    left: 13%;

                    path {
                        fill: vars.$dark2-color;
                    }
                }
            }



            // .mark,
            // .mark::after {
            //     color: vars.$primary-color;
            //     font-weight: 600;
            //     font-size: 30rem;
            //     font-style: italic;

            // }

            // .mark::after {
            //     position: absolute;
            //     z-index: -1;
            //     top: 3%;
            //     left: 13%;
            //     color: vars.$dark2-color;
            // }

            .flipped {
                transform: rotate(180deg);
            }
        }


    }

    .blob-section .container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 22rem;

        .down-arrows {
            z-index: 1;
        }

        .blobs {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(-75%) translateY(0);

            .b8 {}

            .b9 {
                position: absolute;
                right: -70%;
                top: 15%;
            }
        }
    }


    @media (max-width: 1000px) {
        .main {
            flex-direction: column;

            .mark-wrapper {
                .mark {
                    width: clamp(4rem, 10vw, 8rem);
                    transform: none;
                    // transform: translateX(-80%);
                }
            }
        }

    }

    @media (max-width: 800px) {
        .main h1 {
            margin-top: 2rem;
        }

        .blob-section .container {
            height: 12rem;

            .blobs {
                transform: translateX(-45%) translateY(-15%) scale(.45);

                // .b8,
                // .b9 {}
            }
        }
    }
}